import { Calendar } from "@fullcalendar/core";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";
import { DateTime } from "luxon";
import consumer from "../../assets/javascript/channels/consumer";

export default class extends Controller {
  static targets = ["fullcalendar"];

  static values = {
    date: String,
    events: Array,
    teamId: String,
    userId: String,
    activityId: String,
  };

  connect() {
    this.events = this._mapEvents(this.eventsValue);
    this.calendar = this._fullcalendar();

    this.channel = consumer.subscriptions.create(
      {
        channel: "EventChannel",
        team_id: this.teamIdValue,
      },
      {
        received: this._cableReceived.bind(this),
      }
    );

    this.calendar.render();

    var currentTime = DateTime.now();
    if (currentTime.hour > 8) {
      this.calendar.scrollToTime(currentTime.plus({ minutes: -30 }));
    }
  }

  disconnect() {
    this.calendar.destroy();
  }

  _mapEvents(events) {
    return events.map((event) => {
      return {
        id: event.id,
        title: event.title,
        start: event.starts_at,
        end: event.ends_at,
        extendedProps: {
          tooltip: event.tooltip,
          category: event.type,
          userName: event.user_name,
          customerName: event.customer_name,
          colorName: event.color_name,
        },
        borderColor: event.color_hex,
        backgroundColor: event.color_hex,
      };
    });
  }

  _fullcalendar() {
    return new Calendar(this.fullcalendarTarget, {
      initialDate: this.dateValue,
      events: this.events,
      eventClassNames: "rounded-2xl cursor-pointer",
      locale: ptBrLocale,
      plugins: [timeGridPlugin, interactionPlugin],
      height: "calc(100vh - 200px)",
      initialView: "timeGridDay",
      headerToolbar: false,
      dayHeaders: false,
      scrollTime: '07:30:00',
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
        omitZeroMinute: false,
        meridiem: "short",
      },
      snapDuration: "00:30",
      allDaySlot: false,
      nowIndicator: true,
      expandRows: true,
      slotEventOverlap: false,
      selectable: true,
      select: (selectionInfo) => {
        Turbo.visit(
          `/events/new?event[starts_at]=${selectionInfo.startStr}&event[ends_at]=${selectionInfo.endStr}`,
          {
            acceptsStreamResponse: true,
          }
        );
      },

      eventContent: (args) => {
        return this._renderEvent(args);
      },

      eventClick: function (data) {
        data.jsEvent.preventDefault();

        Turbo.visit(`/events/${data.event.id}/edit`, {
          acceptsStreamResponse: true,
        });
      },
    });
  }

  _renderEvent(args) {
    const event = args.event;
    const classes = {
      violet: "bg-violet-200 text-violet-300",
      rose: "bg-rose-200 text-rose-300",
      sky: "bg-sky-200 text-sky-300",
      stone: "bg-stone-200 text-stone-300",
      ocean: "bg-ocean-200 text-ocean-300",
      cherry: "bg-cherry-200 text-cherry-300",
      sun: "bg-sun-200 text-sun-300",
      macaw: "bg-macaw-200 text-macaw-300",
      peach: "bg-peach-200 text-peach-300",
      sand: "bg-sand-200 text-sand-300",
    };

    // 15min
    if (event.end - event.start <= 900000) {
      return {
        html: `
          <i class="flex items-center w-full h-full px-3 not-italic" title="${event.extendedProps.tooltip}">
            <div class="flex w-full justify-between truncate">
              <div class="text-black text-base">
                <span class="font-bold capitalize">
                  ${event.title}
                </span>

                <span class="font-bold mx-2">|</span>

                <span class="text-sm">
                  ${args.timeText}
                  <span class="mx-2">|</span>
                  ${event.extendedProps.userName}
                </span>
              </div>

              <div class="ml-1 inline-flex px-2 py-0.5 rounded-full ${classes[event.extendedProps.colorName]}">
                ${event.extendedProps.category}
              </div>
            </div>
          </i>
        `,
      };
    }

    // 30 min
    if (event.end - event.start <= 1800000) {
      return {
        html: `
          <i class="block w-full h-full px-3 py-2 not-italic" title="${event.extendedProps.tooltip}">
            <div>
              <div class="text-black text-base truncate">
                <span class="font-bold capitalize">
                  ${event.title}
                </span>

                <span class="font-bold mx-2">|</span>

                <span class="text-sm">
                  ${args.timeText}
                  <span class="mx-2">|</span>
                  ${event.extendedProps.userName}
                </span>
              </div>
            </div>

            <div class="inline-flex px-2 py-0.5 mt-2 rounded-full ${classes[event.extendedProps.colorName]
          }">
              ${event.extendedProps.category}
            </div>
          </i>
        `,
      };
    }

    return {
      html: `
        <i class="block w-full h-full px-3 py-2 not-italic" title="${event.extendedProps.tooltip}">
          <p class="text-black text-base font-bold capitalize truncate">
            ${event.title}
          </div>

          <p class="text-sm text-black mt-1 truncate">
            ${args.timeText}
            <span class="mx-2">|</span>
            ${event.extendedProps.userName}
          </p>

          <div class="inline-flex px-2 py-0.5 mt-2 rounded-full ${classes[event.extendedProps.colorName]
        }">
            ${event.extendedProps.category}
          </div>
        </i>
      `,
    };
  }

  _cableReceived(data) {
    const { action, filters, event } = data;

    if (this.userIdValue && this.userIdValue != filters.userId) {
      return;
    }

    if (this.activityIdValue && this.activityIdValue != filters.activityId) {
      return;
    }

    this.calendar.getEventById(event.id)?.remove();

    if (action === "created" || action === "updated") {
      this.calendar.addEvent({
        id: event.id,
        title: event.title,
        start: event.starts_at,
        end: event.ends_at,
        extendedProps: {
          category: event.type,
          userName: event.user_name,
          customerName: event.customer_name,
          colorName: event.color_name,
        },
        borderColor: event.color_hex,
        backgroundColor: event.color_hex,
      });
    }
  }
}
